:root {
    --menu-text: #FFFFFF;
    --main-back-1: #fafbfb; 
    --main-back-1-1: #f7f8fc;
    --main-back-2: #DBDBDB;
    --main-back-3: #3B3C40;
    --main-back-4: #575757;
    --main-back-5: #f8f8f8;
    --main-back-6: #cacaca;
    --main-back-7: #F1F1F1;
    --main-back-8: rgb(221, 221, 221);
    --main-back-9: rgb(250, 250, 250);
    --main-back-10: rgba(0, 0, 0, 0.3);
    --main-back-11: rgb(114, 114, 114);
    --main-back-12: rgba(0, 0, 0, 0.5);
    --main-back-13: rgba(0, 0, 0, 0.87);
    --main-back-14: #A8A8A8;
    --main-back-15: #8A8A8A;
    --pallet-1: #4362E7;
    --pallet-2: rgb(24, 59, 122);
    --pallet-3: #FFFFFF;
    --pallet-4: #919191;

    --primary-color: #2488f7;

    --text-primary: #313131;
    --text-secondary: #989898;
    --text-disabled: #c2c2c2;

    --bg-light: #e9ecf0;
    --bg-primary-light: #f5f7fa;
    --bg-filter: #f5f6fb;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

*{
    box-sizing: border-box;
}

html, body {
    margin: 0;
    font-family: 'Inter', sans-serif;
    background-color: #fafbfb;
}

h1,h2,h3{
    font-family: 'Poppins', sans-serif;
}

.menu{
    width: 260px;
    position: fixed;
    height: 100%;
    overflow: auto;
    padding-bottom: 40px;
    background-color: #fff;
}
.menu ul{
    padding: 12px;
}
.menu li{
    padding: 12px;
    border-radius: 3px;
}
.menu li:hover{
    border-radius: 0;
    border-left: 3px solid var(--primary-color);
    color: var(--primary-color)
}
.menu ul p{
    font-weight: bold;
    font-family: 'Poppins';
    color: #3a3a3a;
    margin: 8px 12px;
    font-size: 14px;
    color: var(--text-primary);
}

.contentContainer { 
    margin-left: 260px;
    min-height: 500px;
    padding: 24px;
}
.bg-primary-light{
    background-color: var(--bg-primary-light);
}
.btn{
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 0.9em;
    white-space:nowrap;
    font-size: 12px;
}
.btn-light {
    background: none;
    color: var(--primary-text);
    font-family:'Poppins';
    font-size: 12px;
    border: 1px solid #dee2e6;
    height: 36px;
}
.btn-custom-primary {
    background:  var(--primary-color);
    color: #fff;
    font-family:'Poppins';
    font-size: 12px;
    border: none;
    height: 36px;
}
.btn-custom-primary:hover {
    border:  1px solid var(--primary-color);
    color: var(--primary-color);
}

.btn-custom-danger {
    background: #dc3545;
    color: #fff;
    font-family:'Poppins';
    font-size: 12px;
    height: 36px;
}
.btn-custom-danger:hover {
    color: #dc3545;
    border: 1px solid #dc3545;
}
.btn-outline-custom-primary{
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    font-family:'Poppins';
    font-size: 12px;
    height: 36px;
}
.btn-outline-custom-primary:hover{
    background-color: var(--primary-color);
    color: #fff
}
.hover-btn span{
    display: none;
}
.hover-btn:hover span{
    display:contents;
}
thead{
    background: none;
}
.modal-90w{
    min-width: 90%;
}
.mainContainer { 
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    background-color: var(--main-back-1);
}

.pagesContent {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
}

.numberPages {
    margin: 0;
    background-color: var(--bg-light);
    border: #787878;
    color: #787878;
    font-weight: bold;
    border-right: none;
    padding: 8px;
    text-align: center;
    cursor: pointer;
}

.numberPages:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.numberPages:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

li{
    list-style: none;
}

.input-file{
    border:3px dashed var(--text-secondary);
}
.input-file:hover{
    background-color: var(--bg-light);
}
.custom-checkbox {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    font-size: 0.7rem;
}

.custom-nav{
    gap: 12px
}
.custom-nav li{
    padding: 8px 24px;
    border-radius: 8px;
    border: 1px solid var(--bg-light);
}
.custom-nav li:hover{
    border: 1px solid var(--primary-color);
    color: var(--primary-color)
}
.btn-nav{
    background: none;
    color: var(--primary-text);
    font-family:'Poppins';
    font-size: 12px;
    border: 1px solid #dee2e6;
    height: 36px;
    min-width: 100px;
}
.btn-nav:hover{
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
}
.btn-nav:focus{
    background-color: var(--primary-color);
    color: #fff;
}
.btn-search{
    font-weight: normal !important;
    font-family: 'Inter', sans-serif;
    border: 1px solid #CED4DA
}
.btn-outline-light-search{
    font-weight: normal !important;
    font-family: 'Inter', sans-serif;
    color: var(--text-secondary);
    border: 1px solid #CED4DA;
}
.container-btn{
    display: flex;
    justify-content: flex-end;
    white-space: nowrap;
    flex-wrap: wrap;
}
.container-btn .btn{
    margin-inline: 6px;
}
.dropdown-toggle::after {
    display: none;
}
/* BO */
.swal-title{
    font-family: 'Poppins';
    font-size: 1.2em;
    text-align: left;
    border-bottom: 1px solid var(--main-back-8);
    padding: 16px;
}
.swal-form{
    padding: 16px;
    height: 36px;
}
.swal-input-label{
    text-align: left !important;
}
.swal-confirm-button{
    align-self: end;
}
.delete-btn{
    visibility: hidden;
}
.select-info{
    display: flex;
    justify-content: space-between;
}
.select-info:hover .delete-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    visibility:visible;
    border: 1px solid var(--text-secondary);
    font-weight: normal;
    border-radius: 8px;
    font-size: 14px;
    width: 26px;
    height: 26px;
}
.import-info {
    display: flex;
    flex-direction: row;
    margin: 0;
    margin-top: -25px;
    width: fit-content;
    justify-content: right;
    margin-left: auto;
}

textarea {
    border-radius: 4px;
    border: 1px solid var(--main-back-8);
    box-shadow: none;
    padding: 10px;
    outline: none;
    margin: 0;
    background: var(--pallet-3);
    cursor: pointer;
    box-sizing: border-box;
    width:100%;
    min-height: 100px;
    resize:vertical;
}

textarea:focus {
    border-color: var(--pallet-1);
}

.bi_content {
    width: 100%;
    margin-top: 10px;
    display: grid;
    grid-template-columns: auto auto auto;
    grid-auto-rows: auto auto auto;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
}

.bi_title {
    width: 100%;
    text-align: center;
    border-bottom: 2px solid rgba(0, 0, 0, 0.3);
    font-weight: bold;
}

.bi_title p{
    margin: 10px;
}

.bi_block {
    background-color: #FFF;
    width: auto;
    border-radius: 10px;
    box-shadow: 1px 1px 8px #000;
}

.content-info {
    display: flex;
    flex-direction: row;
    margin-right: 10px;
}

.counter {
    display: 'flex';
    flex-direction: 'row';
}



.noOrder {
    margin: auto;
    margin-top: 180px;
    text-align: center;
    width: 100%;
}

.export {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    gap: 2%;
}

.export button {
    font-size: 25px;
    width: 100%;
}

.export .csv {
    font-size: 25px;
    width: 100%;
}

.icons {
    font-size: 20px;
    margin-right: 10px;
}

.table_icons {
    font-size: 20px;
}





.title {
    border-radius: 5px;
    padding: 20px;
    margin-top: -45px;
    background-color: var(--pallet-1);
    box-shadow: 2px 2px 5px var(--main-back-3);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.title h1 {
    font-size: 26px;
    color: var(--menu-text);
    margin: 0;
    padding: 0;
}

.title div {
    display: flex;
    flex-direction: row;
    gap: 2%;
    margin-right: 10px;
}

.list_account {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.list_account div {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 1%;
}

.list_account div div {
    display: flex;
    flex-direction: column;
    width: 100%;
}



.list_account_1 {
    display: flex;
    flex-direction: row;
    gap: 2%;
}

.list_account_1 .list {
    height: 500px;
    margin-top: 20px;
    overflow-y: auto;
}

.list_account_1 .list tr:hover {
    background-color: var(--main-back-14);
    cursor: pointer;
}

.list_account div#exception {
    display: flex;
    flex-wrap: wrap;
}

.list_account div#exception div {
    flex: 1 100%;
}

.list_account div#exception div input {
    width: auto;
}

.list_account_1 button {
    margin: 0;
    margin-top: 10px;
}

div#flex1 {
    display: flex;
    flex-direction: row;
}

div#flex2 {
    display: flex;
    flex-direction: column;
}

div#flex3 {
    width: auto;
    margin: auto 80px;
}

img#register {
    width: 128px;
    height: 170px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    box-shadow: 2px 2px 2px var(--main-back-15);
}

img#complementar {
    width: 128px;
    height: 170px;
    border-radius: 10px;
    box-shadow: 2px 2px 2px var(--main-back-15);
    margin-bottom: 5px;
}

div#flex1 {
    display: flex;
    flex-direction: row;
}

div#flex2 {
    display: flex;
    flex-direction: column;
}

div#flex3 {
    width: auto;
    margin: auto 80px;
}

.resume-status {
    width: 20px;
    height:20px;
    color: #fff;
    text-align: center;
    font-weight: bold;
}

img#register {
    width: 128px;
    height: 170px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    box-shadow: 2px 2px 2px var(--main-back-15);
}

.printOrderItem {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
}

.printOrderInfo {
    width: 340px;
}

.printOrderListItem {
    background: #FFFFFF;
    height: auto;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    border-radius: 5px;
    padding: 20px;
    box-shadow: 2px 2px 5px var(--main-back-3);
}

.printOrderListItem div {
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    gap: 2%;
    margin-right: 10px;
}

.printOrderListItem div#identificador {
    font-weight: bold;
    font-size: 20px;
}

@media only screen and (max-width: 460px) {

    div#s1 div {
        flex-direction: column;
    }

    div#s1 div input {
        width: auto;
        margin-top: 10px;
    }
}

@media only screen and (max-width: 460px) {

    div#s1 div {
        flex-direction: column;
    }

    div#s1 div input {
        width: auto;
        margin-top: 10px;
    }
}

@media only screen and (max-width: 1125px) {
    .list_account div {
        flex-direction: column;
        width: 100%;
        gap: 1%;
    }

    .list_account_1 {
        display: flex;
        flex-direction: column;
        gap: 2%;
    }

    .list_account_1 button {
        margin: 0;
        margin-top: 10px;
    }

    .list_account_1 .list {
        height: 250px;
        margin-top: 20px;
        overflow-y: auto;
    }
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
.switch input { 
opacity: 0;
width: 0;
height: 0;
}

.pandar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.autoR {
    display: flex;
    flex-direction: row;
    margin:0;
    padding:0;
}

.autoR p {
    font-size: 18px;
    padding: 0 5px;
    font-weight: bold;
    margin-top: auto;
    margin-bottom: auto;
    margin-right:'0';
    text-align:'left';
}

.slider {
position: absolute;
cursor: pointer;
top: 0;
left: 0;
right: 0;
bottom: 0;
background-color: var(--pallet-4);
-webkit-transition: .4s;
transition: .4s;
}

.slider:before {
position: absolute;
content: "";
height: 26px;
width: 26px;
left: 4px;
bottom: 4px;
background-color: white;
-webkit-transition: .4s;
transition: .4s;
}

.switch input:focus + .slider {
    box-shadow: 0 0 4px var(--pallet-1);
}

.switch input:checked + .slider {
background-color: var(--pallet-1);
}

.switch input:checked + .slider:before {
-webkit-transform: translateX(26px);
-ms-transform: translateX(26px);
transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
border-radius: 34px;
}

.slider.round:before {
border-radius: 50%;
}

button {
    background-color: var(--pallet-4);
    cursor: pointer;
}

.filters {
    display: flex;
    flex-direction: row;
    gap: 2%;
    width: 100%;
    justify-content: space-between;
}


.maps-others {
    width: auto;
    background-color: var(--pallet-4);
    border-radius: 10px;
    padding: 10px 0;
    font-weight: bold;
    color: var(--menu-text);
    margin-bottom: 5px;
    cursor: pointer;
}

select, input {
    border-radius: 4px;
    border: 1px solid var(--main-back-8);
    box-shadow: none;
    padding: 10px;
    transition: 0.5s;
    outline: none;
    width: auto;
    margin: 0;
    background: var(--pallet-3);
    cursor: pointer;
}

.importacao {
    border-radius: 4px;
    border: 1px solid var(--main-back-8);
    box-shadow: none;
    padding: 20px;
    transition: 0.5s;
    outline: none;
    width: auto;
    margin: 0;
    background: var(--pallet-4);
    color: var(--main-back-5);
    cursor: pointer;
}

.filter {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 12px;
}

.filter1 {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.file {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.filter input, .filter select {
    width: 100%;
    box-sizing: border-box;
}

.filter1 input, .filter1 select {
    width: 100%;
    box-sizing: border-box;
}

.filter div {
    width: auto;
}

.date {
    display: flex;
    flex-direction: row;
    gap: 2%;
    width: 100%;
    padding: 0;
    margin: 0;
}

.date input[type="date"] {
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid var(--main-back-8);
    box-shadow: none;
    padding: 8px;
    transition: 0.5s;
    outline: none;
    width: 100%;
    margin: 0;
    background: var(--pallet-3);
    cursor: pointer;
}

.dinput {
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid var(--main-back-8);
    box-shadow: none;
    padding: 8px;
    transition: 0.5s;
    outline: none;
    width: 100%;
    margin: 0;
    background: var(--pallet-3);
    cursor: pointer;
}

.date input[type="date"]:focus {
    border-color: var(--pallet-1);
}

.gmnoprint img, .gm-fullscreen-control img,
.gm-bundled-control img, .gm-bundled-control-on-bottom img,
.gm-control-active img {
    margin: auto;
    padding: auto;
}

.gm-compass-turn img, .gm-compass-turn-opposite img, .gm-compass-needle img {
    margin-top: 10px;
}

select:focus, input:focus {
    border-color: var(--pallet-1);
}

.list {
    width: 100%;
    margin-top: 10px;
    overflow-x: auto;
}

.show_map {
    padding: 10px;
    margin-left: -10px;
}

.show_map:hover {
    cursor: pointer;
    background-color: var(--pallet-1);
    color: var(--main-back-5);
    border-radius: 10px;
}

.list table {
    width: 100%;
    border-collapse: collapse;
    font-family: 'Open Sans', sans-serif;
    margin-bottom: 0px;
    overflow-x: auto;
}



.edit:hover {
    cursor: pointer;
    background: linear-gradient(
        var(--main-back-10), 
        var(--main-back-10)
        );
}

.blocked {
    cursor: not-allowed;
    background: linear-gradient(
        var(--main-back-10), 
        var(--main-back-10)
        );
}

.extra {
    position: absolute;
    display: flex;
    right: 0;
    top: 0;
    width: 100vw;
    min-height: 100%;
    background-color: var(--main-back-12);
}

.extra .extracontainer {
    margin:30px;
    margin-top: 70px;
    min-height: 480px;
    background-color: var(--main-back-2);
    box-shadow: 2px 2px 5px var(--main-back-3);
    border-radius: 5px;
    padding: 10px;
    width: auto;
}

.extra .extracontainer .title {
    width: auto;
    margin-bottom: 10px;
}

.extra .extracontainer .close {
    display: flex;
    justify-content: left;
    margin-top: 20px;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: var(--main-back-7); 
}

::-webkit-scrollbar-thumb {
    border-radius: 24px;
    background: var(--pallet-4); 
}

::-webkit-scrollbar-thumb:hover {
    background: var(--primary-color); 
    
  }

@media only screen and (max-width: 800px) {
    .mainContainer {
        position: initial;
        display: flex;
        flex-direction: column;
        width: 100%;
        min-height: 100vh;
    }

    .pandar {
        display: flex;
        flex-direction: row;
    }   

    .menu {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
        padding: 0;
        background-size: 100% auto;
        background-position: center;
    }
    
    .counter {
        display: flex;
        flex-direction: column;
    }

    .import-info {
        margin: auto;
        margin-top: 10px;
    }

    .menu div#header {
        border-bottom: none;
        margin-top: 10px;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
    }

    .menu ul {
        display: flex;
        flex-direction: row;
        padding: 0;
        font-size: 15px;
        margin: 0;
        width: auto;
        overflow-x: auto;
    }
    
    .menu li {
        margin: 5px 5px;
        padding: 10px;
        width: auto;
    }
    
    .menu li#extras {
        position: relative;
        width: 100px;
    }

    .menu li  ul {
        position: absolute;
        top: 40px;
        left: 0;
        margin: 0;
        width: 120px;
    }

    .contentContainer {
        margin-left: auto;
        margin-right: auto;
    }

    .export {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
    }

    .title h1 {
        font-weight: bold;
    }

    .filters {
        flex-direction: column;
    }

    .filter {
        width: 100%;
    }

    .date {
        flex-direction: column;
        width: 100%;
    }

    .date input {
        width: auto;
    }

    th {
        font-size: 10px;
        font-weight: 400;
    }
}

@media only screen and (max-width: 570px) {
    .title div {
        margin-right: 0px;
    }

    .pandar {
        display: flex;
        flex-direction: column;
    }

    .autoR {
        margin-top: 10px;
        text-align: left;
    }
}

@media only screen and (max-width: 460px) {
    .title {
        flex-direction: column;
    }

    h1 {
        text-align: center;
    }

    .title div {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
    }

    .title div button {
        width: 100%;
    }
}


input#GRF {
    min-width: 380px;
}

.importar {
    display: flex;
    flex-direction: column;
}

.dest {
    display: flex;
    flex-direction: column;
}



@media only screen and (max-width: 800px) {
    input#GRF {
        min-width: auto;
    }
}

.localsContent {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
}

button#clean {
    width: 125px;
}



.locals {
    margin-top: 20px;
}

.locals .form input, .locals .form select {
    width: 100%;
    box-sizing: border-box;
}

.locals .form {
    display: flex;
    flex-direction: column;padding: 0;
    width: 200px;
    padding: 0;
}

.locals .form label {
    margin-top: 0;
}

div#buttons button {
    margin-left: 10px;
}

div#buttons button:first-child {
    margin-left: 0
}

@media only screen and (max-width: 650px) {

    ul#list_config li {
        border: none;
        border-radius: 0;
        border-right: 1px solid var(--main-back-14);
        border-left: 1px solid var(--main-back-14);
        border-top: 1px solid var(--main-back-14);
    }
    
    ul#list_config li:first-child {
        border-radius: 0;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
    
    ul#list_config li:last-child {
        border-radius: 0;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        border-bottom: 1px solid var(--main-back-14);
    }
}

@media only screen and (max-width: 570px) {
    .localsContent {
        flex-direction: column;
        width: 100%;
    }

    .locals .form{
        width: 100%;
    }

    button#clean {
        width: 100%;
    }

    .title {
        flex-direction: column;
    }

    h1 {
        text-align: center;
    }

    .title div {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
    }

    .title div button {
        width: 100%;
    }
}

.filter button {
    width: 100%;
}

.infs input {
    width: 100%;
    box-sizing: border-box;
}

.infs select {
    width: 100%;
    box-sizing: border-box;
}




.qtd-tot {
    text-align: right;
    color: var(--pallet-2);
    font-weight: bold;
}

.dash{
    flex-wrap: nowrap;
}
.sm-noborder{
    border: 1px solid var(--bg-light)
}
@media only screen and (max-width: 800px) {
    .filter button {
        margin-top: 10px;
    }
    .dash{
        flex-wrap: wrap;
    }
}

@media only screen and (max-width: 500px) {
    .pagesContent {
        flex-direction: column;
    }

    .numberPages {
        border: 1px solid var(--pallet-1);
    }

    .numberPages:first-child, .numberPages:last-child {
        border-radius: 0;
    }

    .numberPages:first-child {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
    
    .numberPages:last-child {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    .qtd-tot {
        text-align: center;
    }

    .container-btn .btn, .dropdown{
        width: 100%;
        margin: 4px;
    }
    .dash{
        flex-wrap: wrap;
    }
    .register{
        display: none;
    }
    .sm-noborder{
        border: none;
    }
}