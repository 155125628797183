.nav a{
    color: #ababab
}
.nav.active{
    color: var(--primary-color);
    border-bottom: 2px solid var(--primary-color);
}
thead{
    background-color: #f4f8fb;
}
tbody th{
    font-family: 'Inter', sans-serif;
    font-weight: normal;
}

tbody tr:hover{
    cursor: pointer;
    background-color: var(--bg-light);
}
.filter input, .filter select {
    width: 100%;
    box-sizing: border-box;
    height: 38px;
}
.bg-primary-light{
    background-color: var(--bg-primary-light);
}
.form-select{
    font-size: 16px !important;
    min-height: 38px;
}