.guideblock {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 200px;
    color: var(--text-secondary);
    cursor: pointer
}

.guideblock:hover {
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
}

.form-check label{
    margin-inline: 8px;
}

@media only screen and (max-width: 460px) {
    .custom-nav .btn{
        width: 100%;
    }
}
