.alertWindow {
    display: flex;
    flex-direction:column;
    margin: 20px auto 0 auto;
    max-width: 600px;
    background: #fdeaab;
    border: 2px solid #000000;
    border-radius: 10px;
    box-shadow: 2px 2px 2px #8A8A8A;
}

.paragraph {
    margin: 10px;
}

.invHeader {
    margin-top: 10px;
    border-radius: 5px;
    padding: 20px;
    background-color: var(--pallet-1);
    box-shadow: 2px 2px 5px var(--main-back-3);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.invHeader h1 {
    font-size: 26px;
    color: #FFFFFF;
    margin: 0;
    padding: 0;
}

.listInvExt {
    background: #FFF;
    margin: 10px;
    display: flex;
    flex-direction: row;
    padding: 10px;
    border-radius: 10px;
    justify-content: space-between;
}

.listBroke {
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
}

.listBroke div div button{
    margin: 0;
}

.listBroke div{
    margin-top: auto;
    margin-bottom: auto;
}

.statusAlert {
    padding: 10px;
    min-width:120px;
    width: 100%;
    text-align: center;
    border-radius: 10px;
    color: #F8F8F8;
    font-weight: bold;
    box-sizing:border-box;
}

.fieldValues {
    min-width: 150px;
    width: 100%;
    height: 100%;
    word-break: break-all;
}

.fieldfoto {
    min-width: 150px;
    width: 100%;
    text-align: center;
}

.conclude {
    margin-top: 10px;
    width: 100%;
    background-color:#36afb9 !important;
}

@media only screen and (max-width: 1200px) {
    .listBroke {
        width: auto;
        flex-direction: column;
    }
}

@media only screen and (max-width: 800px) {

    .invHeader {
        margin-top: 10px;
        border-radius: 5px;
        padding: 20px;
        background-color: var(--pallet-1);
        box-shadow: 2px 2px 5px var(--main-back-3);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .invHeader div button{
        margin-top: 10px;
        width: 100%;
    }

    .listInvExt {
        flex-direction: column;
    }

    .listBroke {
        margin-bottom: 10px;
    }

    .fieldfoto {
        margin-top: 5px !important;
        margin-bottom: 5px !important;
    }
}

@media only screen and (max-width: 500px) {
    .invHeader h1 {
        font-size: 20px;
        color: #FFFFFF;
        margin: 0;
        padding: 0;
    }
}